import { render, staticRenderFns } from "./Dashboard.vue?vue&type=template&id=1d3b78f0&"
import script from "./Dashboard.vue?vue&type=script&lang=js&"
export * from "./Dashboard.vue?vue&type=script&lang=js&"
import style0 from "./Dashboard.vue?vue&type=style&index=0&lang=css&"
import style1 from "vue-dialog-drag/dist/vue-dialog-drag.css?vue&type=style&index=1&lang=css&"
import style2 from "vue-dialog-drag/dist/vue-drop-area.css?vue&type=style&index=2&lang=css&"
import style3 from "vue-dialog-drag/dist/dialog-styles.css?vue&type=style&index=3&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer,VFlex,VLayout})
