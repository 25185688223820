<template>
  <v-container fluid grid-list-xl>
    <div style="height: 100px"> </div>
    <v-layout row wrap>
      <!-- Widgets-->
      <v-flex d-flex lg3 sm6 xs12>
        <widget icon="mdi-hospital-building" :title=codeList.length subTitle="꾸러기 소아과 병원" supTitle="Samples" :color="'#008000'"/>
      </v-flex>
<!--      <v-flex d-flex lg3 sm6 xs12>
        <widget icon="mdi-home-analytics " title="0" subTitle="현재 분석중   " supTitle="Samples" :color="'#003bb2'"/>
      </v-flex>-->
      <v-flex d-flex lg3 sm6 xs12>
        <widget icon="mdi-book-edit " :title="noSummeryList.length" subTitle="문진 입력 대기" supTitle="Samples" :color="'#65009d'"/>
      </v-flex>
      <v-flex d-flex lg3 sm6 xs12>
        <widget icon="mdi-file-plus " :title="noCommentList.length" subTitle="Dr's Comment 대기" supTitle="Samples" :color="'#65009d'"/>
      </v-flex>
      <v-flex d-flex lg3 sm6 xs12>
        <widget icon="mdi-file-plus " title="0" subTitle="Report" supTitle="Samples" :color="'#65009d'"/>
      </v-flex>
      <!-- Widgets Ends -->
      <!-- Statistics -->
      <v-flex d-flex lg6 sm6 xs12>
        <v-chart ref=phylum :options="phylum"  theme='shine' />
      </v-flex>
      <v-flex d-flex lg6 sm6 xs12>
        <v-chart :options="entro.avg"  theme='shine' />
      </v-flex>
      <!-- Statistics Ends -->
      <!-- DataTable&TimeLine Starts -->
      <v-flex d-flex lg8 sm6 xs12>
      </v-flex>
      <v-flex d-flex lg4 sm6 xs12>
      </v-flex>
      <!-- DataTable&TimeLine Ends -->
      <v-flex d-flex lg6 sm6 xs12>
      </v-flex>
      <v-flex d-flex lg6 sm6 xs12>
      </v-flex>
    </v-layout>
<!--    <dialog-drag id="dialog-1" title="설문조사 입력" :options="dialogOpts">
      <v-container>
        <v-layout>
          <v-flex  lg12 sm12 xs12>
            <div style="width:600px;height:500px;bottom:10px;padding:40px;border:1px solid #ccc;">
              <h3><b>Summary</b></h3>
              summary를 작성해 주세요.(리포트 생성시 필수)
              <hr class="hr1">
              <editor
                  ref="summaryEditor"
                  :initialValue="summaryText"
                  :options="editorOptions"
                  height="300px"
                  initialEditType="wysiwyg"
                  previewStyle="tab"
              />
              <div style="margin-top:10px">
                <v-btn
                    :loading="loadingSum"
                    :disabled="loadingSum"
                    color="rgb(140, 193, 82)"
                    @click="saveSummary"
                >
                  save
                  <v-icon right dark
                  >
                    mdi-content-save
                  </v-icon>
                </v-btn>
                <v-tooltip
                    v-model="show"
                    top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                    >
                    </v-btn>
                  </template>
                  <span>{{message}}</span>
                </v-tooltip>
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </dialog-drag>-->
<!--    <DialogEditor title="test" ></DialogEditor> -->
  </v-container>
</template>

<script>

export default {
  components: {
    widget: () => import('./Widget'),
  },
  data() {
    return {
    }
  },
  methods : {
    drop (id) {
      console.log('drop id:', id)
    },
    loaded (id) {
      alert(id)
    }
  },
  mounted() {
    if(this.phylum.legend === undefined){
      this.$refs.phylum.showLoading();
    }
  },
  watch: {
    phylum : function(val){
      this.$refs.phylum.hideLoading();
    }
  },
  computed : {
    codeList() {
      return this.$store.state.codeList;
    },
    noSummeryList() {
      //console.log("noCommentList " + this.$store.state.dashBoard.noSummeryList.length);
      return this.$store.state.dashBoard.noSummeryList;
    },
    noCommentList() {
      return this.$store.state.dashBoard.noCommentList;
    },
    phylum()  {
      let avgPie = this.$store.state.phylum.avgPieData;
      //console.log("dashboard %s", JSON.stringify(avgPie));
      return avgPie;
    },
    entro() {
      let entero = this.$store.state.enterotypePieChart;
      entero.avg.legend = false;
      return entero;
    }


  }
}
</script>

<style>
.widget-card {
  border-radius: 5%;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.21);
  background-color: transparent;
}
</style>
<style src="vue-dialog-drag/dist/vue-dialog-drag.css"></style>
<style src="vue-dialog-drag/dist/vue-drop-area.css"></style>

<!-- optional dialog styles, see example -->
<style src="vue-dialog-drag/dist/dialog-styles.css"></style>